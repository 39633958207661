import React from 'react';
import Layout from "@retina-packages/retina-theme-bootstrap/packages/components/layout";
import { graphql } from 'gatsby';
import { HcpMobileBanner, PageWrapper, HcpPage } from '@retina-packages/retina-theme-bootstrap/packages/css/CommonStyle'
import { SupportingYourParentsWrapper, NoCostGeneticTestingWrapper } from './styles'
import MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer'
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes';
import { reusableDataBuilders, deriveCommunityResourceVideo } from '@retina-packages/retina-theme-bootstrap/packages/helpers';
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType';
import retinaConfig from '../../../utils/retinaConfigs';
import { HTMLRenderer } from '@retina-packages/retina-theme-bootstrap';
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles'
import { hcpSYP } from './constants';
import { deriveSiteLogo, deriveSiteMenus, fullHTMLDataBuilder, footRefHTMLBuilder, readCaseModalDataBuilder } from '../../../utils';
import MultiColVideoContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MultiColVideoContainer';

/** Renders supporting-your-patients-page template
 *
 * @param props prop
 * @returns
 */
const SupportingYourPatientsTemplate = (props: Props) => {
  const pgProp: PageQueryResult = props.data
  const blocks = pgProp.nodePage.relationships.field_paragraphs_gcso
  const pgContextsSYP = props.pageContext
  const htmlStaticFilesSYP = {
    nonSVGImages: pgContextsSYP.nonSVGImages,
    svgMediaImages: pgContextsSYP.svgMediaImages,
    allMediaDocument: pgContextsSYP.documents
  }

  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: hcpSYP.hcpHomeMainMenu, siteFooterMenu: hcpSYP.hcpHomeFooterMenu, addHCPPrefix: retinaConfig.addHcpPrefix });
  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name
  const bannerTitle = ""
  const carouselTitle = hcpSYP.hcpHomeCarousel
  const footerTitle = hcpSYP.hcpFooter
  const exitPopupTitle = hcpSYP.hcpExitPopup
  const hcpSwitcherTitle = hcpSYP.hcpHCPSwitcher
  const topNavTitle = hcpSYP.hcpTopNav

  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs
  }: {
    footerText: string;
    exitData: exitPopDataType;
    hcpSwitcher: exitPopDataType;
    topNavs: string;
  } = reusableDataBuilders({ blocks, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle });

  const hcpReferenceSYP = footRefHTMLBuilder({ blocks, title: hcpSYP.hcpFootRef })
  const siteLogos: any = deriveSiteLogo({ blocks, title: hcpSYP.hcpSiteLogo })
  const audioSecHTMLPart = fullHTMLDataBuilder({ blocks, title: hcpSYP.hcpAudioSecHTMLPart })
  const IRDExpertVideo = blocks.filter((crco: any) => crco.relationships.field_reusable_paragraph.label === hcpSYP.hcpIRDExpertVideo).map(
    (crcoi: any) => deriveCommunityResourceVideo(crcoi))?.at(0)
  const bannerHTML = fullHTMLDataBuilder({ blocks, title: hcpSYP.hcpBanner })
  const anchorelink = fullHTMLDataBuilder({ blocks, title: hcpSYP.hcpAnchorelinkPara })
  const htmlData = fullHTMLDataBuilder({ blocks, title: hcpSYP.hcpRealWorldPara })
  const seekTrialData = fullHTMLDataBuilder({ blocks, title: hcpSYP.hcpClinicalTrialPara })
  const modals: any = readCaseModalDataBuilder(blocks, [hcpSYP.hcpRCSModal1, hcpSYP.hcpRCSModal2, hcpSYP.hcpRCSModal3, hcpSYP.hcpRCSModal4, hcpSYP.hcpRCSModal5])
  const logoHtmlRow = fullHTMLDataBuilder({ blocks, title: hcpSYP.hcpLogoData })
  const logoHtmlGrid = fullHTMLDataBuilder({ blocks, title: hcpSYP.hcpLogoHtmlGrid })
  const vidHtmlData = fullHTMLDataBuilder({ blocks, title: hcpSYP.hcpVidHtmlHeading })
  const videoSection = blocks.filter((crco: any) => crco.relationships.field_reusable_paragraph.label === hcpSYP.hcpVideoSection).map(
    (crcoi: any) => deriveCommunityResourceVideo(crcoi))?.at(0)

  return (
    <div className='hcppage'>
      <HcpPage key="hcppage">
        <HcpMobileBanner className="hcpmobilewrapper hcpsupportpatience hcp-supporting-patient-footer-wrapper">
          {props.pageContext !== null && props.pageContext.metaInfo !== null && (
            <MetaTagContainer metaData={props.pageContext.metaInfo} />
          )}
          <Layout
            title={"siteTitle"}
            location={props.location}
            data={mainMenu}
            modals={modals}
            mdata={footerMenu}
            footerData={footerText}
            audience={audience}
            exitPopData={exitData}
            footref={hcpReferenceSYP}
            hcplinks={hcpSwitcher}
            topNavigation={topNavs}
            staticMediaFiles={htmlStaticFilesSYP}
            siteLogos={siteLogos}
            showRefContent={retinaConfig.showRefContent}
            hideRefContent={retinaConfig.hideRefContent}
            backToTopContent={retinaConfig.backToTopContent}
            gtmBackToTopLabel={hcpSYP.backToTopGTM}
            modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
            toggleExpandAllUnderline={false}
            hcpValidate={retinaConfig.hcpValidate}
            hcpHomeUrl={retinaConfig.hcpHomeUrl}
            preIndexUrl={retinaConfig.preIndexUrl}
            hcpPrefix={retinaConfig.hcpPrefix}
            siteHomeUrl={retinaConfig.siteHomeUrl}
            footerClassName={hcpSYP.footerClassName}
            cpraFooter={true}
            cpraFooterEnv={process.env.CPRA_FOOTER_ENV}
            cpraOT={true}
            cpraOTEnv={process.env.OT_ENV}
            cpraOTuuid={process.env.OT_UUID}
            {... { ...retinaConfig.layoutAttrs.hcpAttrs, ...retinaConfig.layoutAttrs.commonAttrs }}
          >
            <PageWrapper className='pagewrapper supporting-your-patients hcp-supporting-your-patient'>
              {/* Retina Canada HCP - "Supporting Your Patients" page - "Re-testing after 1 year" pop-up - EN */}
              <SupportingYourParentsWrapper className='supportparentswrapper'>
                {/* Retina Canada HCP - "Supporting your patients" page - "Connecting Patients with" Banner component-EN */}
                <MobileBanner className="mobile-banner clinical-trials-banner">
                  <HTMLRenderer data={htmlStaticFilesSYP} html={bannerHTML} tagName='section' className='internal-banner o-column--full' simpleImage={true} />
                </MobileBanner>
                <HTMLRenderer data={htmlStaticFilesSYP} html={anchorelink} key="anchorelink" />
                <HTMLRenderer data={htmlStaticFilesSYP} html={audioSecHTMLPart} key="audioSecHTMLPart" />
                <MultiColVideoContainer
                  accountId={retinaConfig.accountId}
                  communityResourcesVideoSection={IRDExpertVideo}
                  viewTranscriptBtnContent={retinaConfig.viewTranscriptBtnContent}
                  hideTranscriptBtnContent={retinaConfig.hideTranscriptBtnContent}
                  {...hcpSYP.videoSpeakersAttrs}
                />
                <HTMLRenderer data={htmlStaticFilesSYP} html={htmlData} key="htmlData" />
                <HTMLRenderer data={htmlStaticFilesSYP} html={logoHtmlGrid} simpleImage={true} key="logoHtmlGrid" />
                <HTMLRenderer data={htmlStaticFilesSYP} html={logoHtmlRow} simpleImage={true} key="logoHtmlRow" />
                <HTMLRenderer data={htmlStaticFilesSYP} html={vidHtmlData} key="vidHtmlHeading" />
                <MultiColVideoContainer
                  accountId={retinaConfig.accountId}
                  communityResourcesVideoSection={videoSection}
                  viewTranscriptBtnContent={retinaConfig.viewTranscriptBtnContent}
                  hideTranscriptBtnContent={retinaConfig.hideTranscriptBtnContent}
                  {...hcpSYP.videoAttrs}
                />
                <HTMLRenderer data={htmlStaticFilesSYP} html={seekTrialData} key="seekTrialData" />
              </SupportingYourParentsWrapper>
            </PageWrapper>
          </Layout>
        </HcpMobileBanner>
      </HcpPage>
    </div>
  )
}

export default SupportingYourPatientsTemplate
export const pageQuery = graphql`
  query ($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      relationships {
        ... HcpSYPPageQuery
      }
    }
  }
`