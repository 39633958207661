export const hcpSYP: any = {
  hcpHomeMainMenu: "Main Navigational Menu HCP GCSO - Retina Global",
  hcpHomeFooterMenu: "Footer Navigation Menu HCP GCSO - Retina Global",
  hcpHomeCarousel: "",
  hcpBanner: "Banner - HTML - Resource Center - HCP - US Global",
  hcpFooter: "Retina Global Footer HCP - US - Global",
  hcpExitPopup: "Retina Canada Exit Popup New - Global",
  hcpHCPSwitcher: "HCP Switcher Modal Pop Up - Global",
  hcpTopNav: "Top Navigational Menu HCP US - Global",
  hcpSiteLogo: "Site logos - Global",
  hcpFootRef: "HCP-Supporting Your Patient-References - Global",
  hcpAnchorelinkPara: "Anchor Link - Resource Center - Retina Global",
  hcpAudioSecHTMLPart: "Audio Heading Resource Center - Global",
  hcpIRDExpertVideo: "Brightcove IRD expert Video- RC - HCP - Global - latest",
  hcpLogoData: "3x3 logo garden Full HTML - Resource Center - Global",
  hcpLogoHtmlGrid: "Get connected  - Resource Center - Full HTML - Global",
  hcpClinicalTrialPara: "HCP-Seeking a Clinical Trial - Resource Center - Global",
  hcpRealWorldPara: "Real World Case Studies - Resource Center - Global",
  hcpRCSModal1: "Revealing Modal - Global",
  hcpRCSModal2: "Overlapping Modal - Global",
  hcpRCSModal3: "Denovo Modal - Global",
  hcpRCSModal4: "Retesting Modal - Global",
  hcpRCSModal5: "Benefit Modal - Global",
  hcpVidHtmlHeading: "Video - Resource Center - Global",
  hcpVideoSection: "Video Section 2 - RC - HCP - Global - latest",
  backToTopGTM: "Back to top - supporting your patients",
  videoAttrs: {
    viewTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "Genetic testing afforded us so many answers? Transcript",
        "className": "gtm-accordion"
      },
      {
        "data-gtm-event-label": "How are retinal diseases inherited? Transcript",
        "className": "gtm-accordion"
      }
    ],
    hideTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "Genetic testing afforded us so many answers? Transcript",
        "className": "gtm-accordion"
      },
      {
        "data-gtm-event-label": "How are retinal diseases inherited? Transcript",
        "className": "gtm-accordion"
      }
    ]
  },
  videoSpeakersAttrs: {
    viewTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "How do we help patients see beyond the clinical impact of vision loss? Transcript",
        "className": "gtm-accordion"
      },
      {
        "data-gtm-event-label": "How can we as doctors help patients be their best advocates? Transcript",
        "className": "gtm-accordion"
      },
      {
        "data-gtm-event-label": "What does the IRD community need now and in the future? Transcript",
        "className": "gtm-accordion"
      }
    ],
    hideTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "How do we help patients see beyond the clinical impact of vision loss? Transcript",
        "className": "gtm-accordion"
      },
      {
        "data-gtm-event-label": "How can we as doctors help patients be their best advocates? Transcript",
        "className": "gtm-accordion"
      },
      {
        "data-gtm-event-label": "What does the IRD community need now and in the future? Transcript",
        "className": "gtm-accordion"
      }
    ]
  },
  footerClassName: "resource-center-footer"
}